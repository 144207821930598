import {NavHashLink} from 'react-router-hash-link'
import classNames from 'classnames'

import styles from './Card.module.scss'

interface ICardProps {
  className?: string
  logoUrl?: string
  comingSoon?: boolean
  clickLabel?: string
  link?: string
  hashLink?: string
}

const Card: React.FC<ICardProps> = ({
  className,
  logoUrl,
  comingSoon,
  clickLabel,
  link,
  hashLink,
}) => {
  return !!link ? (
    <a
      className={classNames(styles.card, className, {
        [styles.comingSoon]: comingSoon,
        [styles.topLabel]: !!clickLabel,
        [styles.onHover]: !!clickLabel,
      })}
      href={link}
      target="_blank"
      rel="noopener noreferrer">
      {!!clickLabel && (
        <div className={styles.typeContainer}>
          <div className={styles.type}>{clickLabel}</div>
        </div>
      )}
      {!!clickLabel && <div className={styles.clickLabel}>Click here</div>}
      {logoUrl && <img src={logoUrl} alt="Vertical" />}
    </a>
  ) : !!hashLink ? (
    <NavHashLink
      className={classNames(styles.card, className, {
        [styles.comingSoon]: comingSoon,
        [styles.topLabel]: !!clickLabel,
        [styles.onHover]: true,
      })}
      to={hashLink}
      smooth>
      {!!clickLabel && (
        <div className={styles.typeContainer}>
          <div className={styles.type}>{clickLabel}</div>
        </div>
      )}
      {!!clickLabel && <div className={styles.clickLabel}>Click here</div>}
      {logoUrl && <img src={logoUrl} alt="Vertical" />}
    </NavHashLink>
  ) : (
    <div className={classNames(styles.card, className, {[styles.comingSoon]: comingSoon})}>
      {logoUrl && <img src={logoUrl} alt="Vertical" />}
    </div>
  )
}
export default Card
