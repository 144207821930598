import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './Welcome.module.scss'

const Welcome = () => (
  <BannerSection>
    <BannerContainer className={styles.container}>
      <img className={styles.image} src="/images/chain-neon.svg" alt="Chain" />
      <div className={styles.textWrapper}>
        <span className={styles.title}>
          Scale
          <br /> your business
          <br /> with blockchian
        </span>
        <p className={styles.description}>
          Take your business to the next <br />
          level
        </p>
      </div>
    </BannerContainer>
  </BannerSection>
)

export default Welcome
