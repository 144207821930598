import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './WeAreMadeOf.module.scss'

const WeAreMadeOf = () => (
  <BannerSection className="dark-background">
    <BannerContainer>
      <div className={styles.textWrapper}>
        <span className={styles.title}>What we're made of</span>
        <p className={styles.description}>
          We are a technology company dedicated to provide solutions on Blockchain. We are focused
          on the tokenization of assets, with smart contracts internationally audited by Certik.
        </p>
      </div>
    </BannerContainer>
  </BannerSection>
)

export default WeAreMadeOf
