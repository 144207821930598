import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './BaaS.module.scss'

const BaaS = () => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      <img className={styles.image} src="/images/baas-neon.svg" alt="BaaS" />
      <div className={styles.textWrapper}>
        <span className={styles.title}>
          BaaS <br /> Blockchain <br /> as a Service
        </span>
        <p className={styles.description}>
          In every vertical, we implement the concept of "Blockchain as a Service". This methodology
          allow us to get our clients integrated with Blockchain on a agile and efficient way.
        </p>
      </div>
    </BannerContainer>
  </BannerSection>
)

export default BaaS
