import {useState} from 'react'

import {languagesWithImage} from '../../constants/arrays'
import BannerContainer from '../../containers/BannerContainer'
import Dropdown from '../Dropdown/Dropdown'

import styles from './Header.module.scss'

const Header = () => {
  const [languageSelected, setLanguageSelected] = useState(languagesWithImage[0])

  const onChangeLanguage = (value: string) => {
    setLanguageSelected(languagesWithImage.filter(language => language.text === value)[0])
  }

  return (
    <BannerContainer className={styles.row}>
      <img className={styles.logo} src="/images/logos/blockchain-solutions.svg" alt="kphi-logo" />
      <Dropdown
        className={styles.dropdown}
        value={languageSelected}
        setValue={(value: any) => onChangeLanguage(value)}
        options={languagesWithImage}
        isWithImage
        customClasses={{
          head: styles.languageDropdownHead,
          avatar: styles.languageDropdownAvatar,
          arrow: styles.languageDropdownArrow,
        }}
      />
    </BannerContainer>
  )
}

export default Header
