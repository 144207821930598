import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Button from '../Button/Button'

import styles from './Metaverse.module.scss'

const Metaverse = () => (
  <BannerSection className="dark-background" id="metaverse">
    <BannerContainer className={styles.container}>
      <div className={styles.textWrapper}>
        <span className={styles.title}>
          Your brand <br />
          on the <br />
          metaverse
        </span>
        <p className={styles.description}>
          We design and create your customized space in the Metaverse and wearables.
        </p>
        {/* <Button className={styles.button} text="KPHI Marketing" link="https://" /> */}
        <Button
          className={styles.button}
          text="Get yours"
          link="https://forms.gle/K7PgXRpcxP144qFCA"
        />
      </div>
      <img className={styles.image} src="/images/metarvese.svg" alt="Token" />
    </BannerContainer>
  </BannerSection>
)

export default Metaverse
