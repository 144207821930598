import partnersStyles from '../components/Partners/Partners.module.scss'

export const languages = [{text: 'English'}, {text: 'Spanish'}]

export const languagesWithImage = [
  {text: 'En', image: '/images/icons/en-flag.svg'},
  {text: 'Es', image: '/images/icons/es-flag.svg'},
]

export const categories = [
  {
    title: 'Kephi Gallery',
    links: [
      {name: 'Explore', link: ''},
      {name: 'My items', link: ''},
      {name: 'Following', link: ''},
      {name: 'Activity', link: ''},
    ],
  },
  {
    title: 'Community',
    links: [
      {name: 'Explore', link: ''},
      {name: 'My items', link: ''},
      {name: 'Following', link: ''},
      {name: 'Activity', link: ''},
    ],
  },
  {
    title: 'Marketing',
    links: [
      {name: 'Explore', link: ''},
      {name: 'My items', link: ''},
      {name: 'Following', link: ''},
      {name: 'Activity', link: ''},
    ],
  },
]

export const verticals = [
  {
    name: 'KephiGallery',
    logoUrl: '/images/logos/kephi-gallery.svg',
    isComingSoon: false,
    clickLabel: 'Visit the P2P Art Gallery',
    link: 'https://exchange.kephi.io/',
  },
  // {name: 'RealEstate', logoUrl: '/images/logos/kephi-real-estate.svg', isComingSoon: true},
  {
    name: 'KephiMarketing',
    logoUrl: '/images/logos/kephi-marketing.svg',
    isComingSoon: false,
    link: 'https://marketing.kephi.io',
  },
]

export const socials = [
  {
    name: 'facebook',
    icon: 'images/icons/facebook.svg',
    url: 'https://www.facebook.com/KephiGallery/',
  },
  {name: 'twitter', icon: 'images/icons/twitter.svg', url: 'https://twitter.com/KephiGallery'},
  {
    name: 'instragram',
    icon: 'images/icons/instagram.svg',
    url: 'https://instagram.com/kephigallery?igshid=YmMyMTA2M2Y=',
  },
  // {
  //   name: 'youtube',
  //   icon: 'images/icons/youtube.svg',
  //   url: 'https://www.youtube.com/channel/UCgQtaGud_JYKWPzu05NxLhA',
  // },
  {name: 'github', icon: 'images/icons/github.svg', url: 'https://github.com/orgs/kephi-gallery/'},
  {
    name: 'linkedin',
    icon: 'images/icons/linkedin.svg',
    url: 'https://www.linkedin.com/company/kephi-gallery/',
  },
  {name: 'discord', icon: 'images/icons/discord.svg', url: 'https://discord.com/invite/YMgwGeWQ'},
  {name: 'telegram', icon: 'images/icons/telegram.svg', url: 'https://t.me/KephiGallery'},
]

export const partners = [
  // {
  //   name: 'Kardiachain',
  //   logoUrl: '/images/logos/kardiachain.svg',
  //   link: 'https://kardiachain.io/',
  //   className: partnersStyles.kardiachainLogo,
  // },
  {
    name: 'Zurbaran',
    logoUrl: '/images/logos/zurbaran.svg',
    link: 'https://exchange.kephi.io/gallery/zurbaranGallery',
    className: partnersStyles.zurbaranLogo,
  },
  {
    name: 'CriptoKuantica',
    logoUrl: '/images/logos/criptokuantica.svg',
    link: 'https://www.criptokuantica.com/',
    className: partnersStyles.criptoKuanticaLogo,
  },
  {
    name: 'BeatBlox',
    logoUrl: '/images/logos/beatblox.svg',
    link: 'https://beatblox.market',
    className: partnersStyles.beatbloxLogo,
  },
  // {
  //   name: 'Rubic',
  //   logoUrl: '/images/logos/rubic.svg',
  //   // link: 'https://',
  //   className: partnersStyles.rubicLogo,
  // },
  {
    name: 'Chainlink',
    logoUrl: '/images/logos/chainlink.svg',
    link: 'https://chain.link/',
    className: partnersStyles.chainlinkLogo,
  },
  {
    name: 'Ki',
    logoUrl: '/images/logos/ki.svg',
    link: 'https://kardiainfo.com/',
    className: partnersStyles.kiLogo,
  },
  {
    name: 'GTS',
    logoUrl: '/images/logos/genera-tu-sueldo.svg',
    link: 'https://www.youtube.com/channel/UC25MQXq1KOh6K2Y36lsLsAg',
    className: partnersStyles.gtsLogo,
  },
  {
    name: 'BeInCrypto',
    logoUrl: '/images/logos/be-in-crypto.svg',
    link: 'https://es.beincrypto.com/?utm_medium=kephi',
    className: partnersStyles.beInCryptoLogo,
  },
  {
    name: 'Redbee',
    logoUrl: '/images/logos/logo-redbee.png',
    link: 'https://play.decentraland.org/?realm=dg&position=41%2C43',
    className: partnersStyles.redbee,
  },

  // {
  //   name: 'Bitcourier',
  //   // logoUrl: '/images/logos/bitcourier-row-white-logo.svg',
  //   logoUrl: '/images/logos/bitcourier-column-white-logo.svg',
  //   link: 'https://bitcourier.co.uk/news/kephi-interview',
  //   className: partnersStyles.bitcourierLogo,
  // },
]
