import {verticals} from '../../constants/arrays'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import Card from '../Card/Card'

import styles from './Verticals.module.scss'

const Verticals = () => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      {verticals.map(vertical => (
        <Card
          className={styles.card}
          key={vertical.name}
          logoUrl={vertical.logoUrl}
          comingSoon={vertical.isComingSoon}
          clickLabel={vertical.clickLabel}
          link={vertical.link}
        />
      ))}
    </BannerContainer>
  </BannerSection>
)

export default Verticals
