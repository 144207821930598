import BaaS from '../BaaS/BaaS'
// import BuyKphiToken from '../BuyKphiToken/BuyKphiToken'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import KephiAudits from '../KephiAudits/KephiAudits'
import Metaverse from '../Metaverse/Metaverse'
import Partners from '../Partners/Partners'
// import Stake from '../Stake/Stake'
import TCommerce from '../TCommerce/TCommerce'
import Verticals from '../Verticals/Verticals'
import WeAreMadeOf from '../WeAreMadeOf/WeAreMadeOf'
import Welcome from '../Welcome/Welcome'

const Home = () => (
  <>
    <Header />
    <Welcome />
    <Verticals />
    <WeAreMadeOf />
    <BaaS />
    <TCommerce />
    <KephiAudits />
    {/*<BuyKphiToken />*/}
    {/*<Stake />*/}
    <Metaverse />
    {/* <WhyChooseUs /> */}
    <Partners />
    <Footer />
  </>
)

export default Home
