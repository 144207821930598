import classNames from 'classnames'
import {Autoplay, Pagination} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react'

import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/scss/autoplay'
import 'swiper/scss/free-mode'

import {defaultSettings} from '../../constants/sliderSettings'

import styles from './Slider.module.scss'

interface ISwiperProps {
  className?: string
  customClasses?: {
    swiperSlide?: string
    slide?: string
  }
  slides: any[]
  renderSlide: (slide: any, slideClass: string) => JSX.Element
  settings?: {
    speed: number
    slidestoshow: number
    spaceBetween: number
    autoplay?: boolean
    loop?: boolean
    breakpoints?: any
  }
}

const Slider: React.FC<ISwiperProps> = ({
  className,
  customClasses = {},
  slides,
  renderSlide,
  settings = {},
}) => {
  const modules = [Pagination, Autoplay]

  return (
    <Swiper
      slidesPerView={1}
      pagination={{
        horizontalClass: styles.horizontal,
        clickable: true,
        bulletClass: styles.bullet,
        bulletActiveClass: styles.bulletActive,
      }}
      modules={modules}
      className={classNames(styles.swiper, className)}
      {...defaultSettings}
      {...settings}>
      {slides.map(slide => (
        <SwiperSlide
          key={slide.name || slide.title}
          className={classNames(styles.swiperSlide, customClasses.swiperSlide)}>
          {renderSlide(slide, classNames(styles.slide, customClasses.slide))}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default Slider
