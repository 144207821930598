import classNames from 'classnames'

import Card from '../components/Card/Card'
import Value from '../components/WhyChooseUs/Value/Value'

export const renderPartner = (partner: any, slideClass: string) => (
  <Card
    key={partner.name}
    className={classNames(slideClass, partner.className)}
    logoUrl={partner.logoUrl}
    comingSoon={partner.isComingSoon}
    link={partner.link || null}
  />
)
export const renderValue = (value: any, slideClass: string) => (
  <Value
    key={value.title}
    className={classNames(slideClass, value.className)}
    title={value.title}
    text={value.description}
  />
)

export const renderVertical = (vertical: any, slideClass: string) => (
  <Card
    key={vertical.name}
    className={classNames(slideClass, vertical.className)}
    logoUrl={vertical.logoUrl}
    comingSoon={vertical.isComingSoon}
    clickLabel={vertical.clickLabel}
    link={vertical.link}
  />
)
