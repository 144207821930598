import 'swiper/css/bundle'

import {partners} from '../../constants/arrays'
import {partnersSettings} from '../../constants/sliderSettings'
import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'
import {renderPartner} from '../../utils/renderSlides'
import Slider from '../Slider/Slider'

import styles from './Partners.module.scss'

const Partners = () => (
  <BannerSection className={styles.section}>
    <BannerContainer className={styles.container}>
      <div className={styles.textWrapper}>
        <span className={styles.title}>Our Customers</span>
        <div className={styles.sliderWrapper}>
          <Slider
            slides={partners}
            className={styles.slider}
            customClasses={{
              swiperSlide: styles.swiperSlide,
              slide: styles.slide,
            }}
            renderSlide={renderPartner}
            settings={partnersSettings}
          />
        </div>
      </div>
    </BannerContainer>
  </BannerSection>
)

export default Partners
