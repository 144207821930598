import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './TCommerce.module.scss'

const TCommerce = () => (
  <BannerSection className="dark-background">
    <BannerContainer className={styles.container}>
      <div className={styles.textWrapper}>
        <span className={styles.title}>T-Commerce</span>
        <p className={styles.description}>
          Scale your traditional E-Commerce business to blockchain. Tell us about your business and
          start operating on Web3 with our White Label solution without intermediaries.
          <br /> Tokenize your business, say hello to the Tokenized-Commerce
        </p>
      </div>
      <img className={styles.image} src="/images/t-commerce-neon.svg" alt="Stairs" />
    </BannerContainer>
  </BannerSection>
)

export default TCommerce
