export const defaultSettings = {
  speed: 500,
  slidestoshow: 1,
  spaceBetween: 20,
  autoplay: false,
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    400: {
      slidesPerView: 1.5,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 1.8,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    800: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    960: {
      slidesPerView: 3.25,
      spaceBetween: 20,
    },
    1120: {
      slidesPerView: 3.5,
      spaceBetween: 20,
    },
    1380: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  },
}

export const valuesSettings = {
  speed: 500,
  slidestoshow: 4,
  spaceBetween: 5,
  breakpoints: {
    320: {
      slidesPerView: 1.25,
    },
    375: {
      slidesPerView: 1.5,
    },
    420: {
      slidesPerView: 1.75,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 2.5,
    },
    800: {
      slidesPerView: 3,
    },
    960: {
      slidesPerView: 3.25,
    },
    1120: {
      slidesPerView: 3.5,
    },
    1380: {
      slidesPerView: 4,
    },
  },
}

export const partnersSettings = {
  speed: 1500,
  slidestoshow: 1,
  spaceBetween: 10,
  autoplay: true,
  loop: true,
  breakpoints: {
    320: {
      slidesPerView: 1.25,
    },
    375: {
      slidesPerView: 1.5,
    },
    420: {
      slidesPerView: 1.75,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 2.5,
    },
    800: {
      slidesPerView: 3,
    },
    960: {
      slidesPerView: 3.25,
    },
    1120: {
      slidesPerView: 3.5,
    },
    1380: {
      slidesPerView: 4,
    },
  },
}
