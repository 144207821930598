import BannerContainer from '../../containers/BannerContainer'
import BannerSection from '../../containers/BannerSection'

import styles from './KephiAudits.module.scss'

const KephiAudits = () => (
  <BannerSection className={styles.section}>
    <BannerContainer>
      <img className={styles.image} src="/images/certik-neon.svg" alt="Certik" />
      <div className={styles.textWrapper}>
        <span className={styles.title}>Kephi Audits</span>
        <p className={styles.description}>
          All our Smart Contracts are reviewed, certificated and approved by Certik, which give our
          customers security over their tokenized assets.
        </p>
        <a
          href="https://www.certik.com/projects/kephi-gallery?utm_source=CMC&utm_campaign=AuditByCertiKLink"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}>
          <img className={styles.certik} src="/images/logos/certik.svg" alt="Certik" />
        </a>
      </div>
    </BannerContainer>
  </BannerSection>
)

export default KephiAudits
